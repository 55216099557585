<!-- Navbar Start -->
<app-navbar>
</app-navbar>
<!-- Navbar End -->


<div id="hero-area" class="hero-area-bg">
  <div class="container">
    <div class="">
      <h1>Terms of Service</h1>
      <p>&nbsp;</p>
      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Welcome to Angsaku!</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>
      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Introduction</p>
        <p class="content">
          Angsaku (hereinafter referred as "Angsaku") Terms of Service (hereinafter referred as "Terms") governs the use
          of Angsaku mobile applications, websites and services (hereinafter collectively referred as "the Services")
          which used by any users of the Angsaku Mobile Application, users of Angsaku Property Management System and
          Joint
          Management Body (JMB), Management Corporation (MC), sub-MC, Residents Association (RA), Management Corporation
          Strata Title (MCST) (hereinafter collectively referred as "user", “users”, “you”), that have agreed to use the
          Services. Reference to “Property Manager” in these terms means the Joint Management Body (JMB), Management
          Corporation (MC), sub-MC, Residents Association (RA), Management Corporation Strata Title (MCST).
          In order to use Angsaku and the Services, you are obligated to read and comply with the Terms. You should
          review
          and fully understand each Terms of Service, especially those which relates to the exemption or limitation
          of
          liability, and all terms of service for general use or use of specific services. You shall use the services in
          accordance with the Terms. You may not use the services unless you have agreed to the Terms, such agreement is
          valid and irrevocable. Minors may use the service only with consent from their parents or legal guardian. By
          using the service, you are deemed to have agreed to the Terms where such agreement is valid and irrevocable.
          If
          there is any separate Terms, you shall comply with both Terms.
        </p>
        <p class="content">In order to use Angsaku and the
          Services, you are obligated to read and comply with the Terms. You should review and fully understand each
          Terms of Service, especially those which relates to the exemption or limitation of liability, and all
          terms of service for general use or use of specific services. You shall use the services in accordance with
          the Terms. You may not use the services unless you have agreed to the Terms, such agreement is valid and
          irrevocable. Minors may use the service only with consent from their parents or legal guardian. By using the
          service, you are deemed to have agreed to the Terms where such agreement is valid and irrevocable. If there is
          any separate Terms, you shall comply with both Terms.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Our Service</p>
        <p class="content">You may access and use Angsaku
          services in accordance with this Agreement. You will adhere to all laws, rules, and regulations applicable to
          your use of Angsaku services.
        </p>
        <p class="content">The Service allows you to
          discover, watch and share videos and other content, provides a forum for people to connect, inform, and
          inspire others across the globe, and acts as a distribution platform for original content creators and
          advertisers large and small. We provide lots of information about our products and how to use them in our Help
          Center.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Your Service Provider</p>
        <p class="content">The entity providing the Service is BeanCow PLT, a company operating under the laws of
          Malaysia, located at NO 72, JALAN MP18, TAMAN MERDEKA PERMAI, MELAKA, 75350, MALAYSIA (referred to as
          “Angsaku”,
          “we”, “us”, or “our”). References to Angsaku’s “Affiliates” in these terms means the other companies within
          the
          Angsaku corporate group (now or in the future).
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Applicable Terms</p>
        <p class="content">Your use of the Service is subject to these Terms, the Guidelines and the Policy, Safety and
          Copyright Policies which may be updated from time to time (together, this "Agreement"). Your Agreement with us
          will also include the Advertising on Angsaku Policies if you provide advertising or sponsorships to the
          Service or incorporate paid promotions in your content. Any other links or references provided in these terms
          are for informational use only and are not part of the Agreement.</p>
        <p class="content">Please read this Agreement
          carefully and make sure you understand it. If you do not understand the Agreement, or do not accept any part
          of it, then you may not use the Service.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Third Party Provider</p>
        <p class="content">The Platform and/or Services may contain certain content, promotion, goods and/or services
          supplied by a third party. It may also contain certain hyperlinks to other websites which are neither
          maintained nor controlled by us (“Third Party Service”). Such Third Party Service is provided to you as a
          matter of convenience only.
        </p>
        <p class="content">Any dealings with such third
          party are solely between you and such third party. We encourage you to read their Terms of Service
          carefully, including their privacy policy. We are not responsible and we shall not be liable for any
          transaction entered into with such third party.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Who may use the Service?</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Age Requirements</p>
        <p class="content">You must be at least 17 years old
          to use the Services. However, children of all ages may use if enabled by a parent or legal guardian.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Businesses</p>
        <p class="content">If you use the service on behalf
          of, or for the purposes for a business enterprise, then that business enterprise shall also be deemed to have
          agreed to the Terms, such agreement is valid and irrevocable.</p>
      </div>

      <p>&nbsp;</p>

      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Your Use of the Service</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>
      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">User Account</p>
        <p class="content">You are solely responsible for
          the proper use and confidentiality of your Login Credentials. You shall ensure that you have at all times full
          control of and over the use of all such Login Credentials. Angsaku shall be entitled to treat all
          communications, transactions and other activities occurring under or referable to any such Login Credentials
          as having been carried out by you or on your behalf and with your knowledge and authority. Angsaku shall not
          be liable for any losses, damage, costs, fees or expenses arising out of or in connection with any
          communication, transaction or activity carried out under or referable to any Login Credentials assigned to
          you.
        </p>
        <p class="content">You shall not set up multiple
          accounts, or transfer or sell your account or user ID to another party. You must not use another user&apos;s
          account without their permission.
        </p>
        <p class="content">All user accounts must be registered with a valid personal email address that you access
          regularly, so that, among other things, Amgsaku may communicate with you by email in respect of the management
          and administration of your User Account. Any user accounts which have been registered with someone else’s
          email address or with temporary email addresses may be closed by us without prior notice. Angsaku may require
          users to re-validate their user accounts from time to time in order to ensure that the user accounts are still
          active and registered with a valid email address.
        </p>
        <p class="content">When you create an account, you represent and warrant that:</p>
        <ul class="content">
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">If you are individual, you are at least 17 years of
              age.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">If you are representing a company, organization or any
              other legal entity (&ldquo;Entity&rdquo;), you have authority to bind the Entity to these Terms.</span>
          </li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">You are capable of entering into and performing legally
              binding contracts under applicable law.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">You are and will be legally, financially and morally
              responsible for all activities that occur under your user account.&nbsp;</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">All information which you provide is accurate, up to date,
              truthful and complete and that you will promptly inform us of any changes to such information by updating
              your account profile.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">You agree to provide us with such proof of identity and
              other documentary evidence as we may reasonably request or require in order to verify our records of your
              personal particulars.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">The security company employed at the Property has and
              maintains a valid license as required by the relevant authorities and/or laws of your country.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">Angsaku shall not be responsible or liable for any losses,
              damages or liabilities that may arise from or is caused by any failure or delay of the security guards at
              the Property to respond to an emergency situation at the Property including activations of the emergency
              assist by End Users; and any violation or contravention</span></li>
        </ul>
      </div>


      <p>&nbsp;</p>
      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Content on the Service</p>
        <p class="content">You bear sole legal and other
          responsibilities for the content that is available on Angsaku. Your Content includes any conversation logs, or
          materials on the system. Your Content may include, but is not limited to: conversation logs, media files,
          image files, location data, documents, etc.
        </p>
      </div>
      <p>&nbsp;</p>
      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Permissions and Restrictions</p>
        <p class="content">This regulation refers to any
          content generated, copied, published and spread during the use of Angsaku and the services, including but not
          limited to account profile picture, name, user guide and other relevant registration information, or any text,
          voice clip and image sent, replied or relevant linked page, and other content generated during the use of
          Angsaku and the services.
        </p>
        <p class="content">You have deemed to acknowledge
          and accept that, you are prohibited to use Angsaku or the services to produce, copy, publish or spread
          contents that disrupt Angsaku operations, or violate the right of other users or third parties, including but
          not limited to:
        </p>

        <p class="content">(1) Violation of basic principle determined by constitution;</p>
        <p class="content">(2) Jeopardize national security, leak of state secrets, subverting state power, undermining
          national unity;</p>
        <p class="content">(3) Harm the nation’s reputation and interest;</p>
        <p class="content">(4) Incitement to ethnic hatred, ethnic discrimination, undermining national unity;</p>
        <p class="content">(5) Violate the state religion policies, spread cult and feudal superstition;</p>
        <p class="content">(6) spread rumors, disturbs social order or disrupt social stability;</p>
        <p class="content">(7) spreading obscenity, pornography, gambling, violence, terror or instigate crimes;</p>
        <p class="content">(8) Insult or slander others, infringe upon the legal rights of others;</p>
        <p class="content">(9) Incitement to illegal assembly, of association, of procession and of demonstration, a mob
          to disturb social order;</p>
        <p class="content">(10) Illegal activities in the name of civil society organizations;</p>
        <p class="content">(11) Publish, transmit, spread, store content that infringe individual reputation right,
          right of publicity, intellectual property right, commercial trade secret and other legal rights;</p>
        <p class="content">(12) Containing contents prohibited by Law of Malaysia and administrative regulations.</p>
        <p class="content">(13) Use the Services for any purpose for which it is not designed or intended.</p>
        <p class="content">(14) Use the Services to create or promote a product, service or software that is, directly
          or indirectly, competitive with or in any way a substitute for the Services or any services, product or
          software offered by Angsaku.</p>
        <p class="content">(15) Use any proprietary information or interfaces of Angsaku or any other intellectual
          property of Angsaku in the design, development, manufacture, licensing or distribution of any application,
          accessories or devices for use with the Services.</p>
        <p class="content">(16) Use the Services to send automated, unsolicited or unauthorised messages, advertising or
          promotional material or any junk mail, spam or chain letters.</p>
        <p class="content">(17) Use any scraper, robot, bot, spider, crawler or any other automated device or means to
          access, acquire, copy or monitor any portion of the Services, or any data or content found or access through
          the Services.</p>
        <p class="content">(18) Intercept any communications transmitted by way of a telecommunications system.</p>
        <p class="content">(19) Access or use this Services in such a way as to, or commit any act that imposes or is
          likely or calculated to impose an unreasonable or disproportionately large load on our infrastructure.</p>
        <p class="content">(20) Execute any form of network monitoring which will intercept data not intended for you.
        </p>
        <p class="content">(21) Collect any information in respect of other users without their consent.</p>
        <p class="content">(22) Copy, reproduce, modify, create derivative works from, distribute, or publicly display
          any content from this Services without the prior express written permission of the Angsaku and applicable
          third party.</p>
        <p class="content">(23) Authorise or encourage anyone to do any of the foregoing.</p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Reservation</p>
        <p class="content">Using the Service does not grant
          you any ownership of or rights to any aspect of the Service, including account or any other Content posted by
          others or Angsaku.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Changes to the Service</p>
        <p class="content">Angsaku reserves the right to
          modify and improve the Services, without any prior notice to the User. The changes include
          performance/security enhancement, new functionality &amp; features, UI changes or any changes to comply with
          law. We may still do provide notice when we discontinue or make changes to our Service which will have an
          impact on use of our Services. However, you understand and agree that there will be period where we make these
          changes without any prior notice when necessary.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Uploading Content</p>
        <p class="content">If you choose to upload Content,
          you understand and agree not to submit any Content that breach this Agreement. This includes, but is not
          limited to: intellectual property infringement, copyrighted material, image misappropriation, hacking, fraud,
          phishing, or any other illegal or unlawful activity.
        </p>
        <p class="content">For security reason, we may use
          programmed system or human to analyze your Content to assists on detect infringement and abuse, such as spam,
          malware, and illegal content.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Buying, selling, renting and interacting with other Users</p>
        <p class="content">The Services may provide a
          platform for users to interact with each other, and to buy, sell and rent items. Angsaku does not pre-screen a
          user or the Content provided by a user, nor is Angsaku directly involved in transactions between users.
          Consequently, Angsaku has no control over, and you agree that Angsaku is not responsible or liable for, any of
          the following:
        </p>
        <ul class="content">
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">The quality, safety, morality or legality of any aspect of
              the comments and items listed.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">The truth or accuracy of the listings, the ability of
              sellers to sell items or the ability of buyers to pay for items.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">The true identity, age, nationality, or sense of humour of
              a user.</span></li>
          <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">Any Content posted by users.</span></li>
        </ul>

        <p class="content">You are encouraged to use the
          features and functions available on the Services to communicate with other users and/or to find out more about
          an item or postings which a user has put up. However, please exercise common sense and good judgment in your
          interactions with other users. While Angsaku endeavours to keep the Services safe for everyone, your use of
          the Services and your interactions with other users is entirely at your own risk.
        </p>

        <p class="content">In using the Services to create and/or comment on chatterbox, post
          a find a buddy listing, using the in-app chat function, create a listing for an item for sale or rent, you
          agree to comply with all items stipulated in this Terms of Service.
        </p>

        <p class="content">In using the Services to create a listing and an item for sale or
          rent, you further agree to comply with the following:
        </p>
        <ul class="content">
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">All items must comply with these Terms
              and Angsaku policies</span></li>
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">You must provide a fair, accurate and
              complete description of each item, including your price for the item.</span></li>
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">Each item must have its own
              listing.</span></li>
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">Your listing for an item may only
              include text, descriptions, graphics, images and other content relevant to the item.</span></li>
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">All items must be listed in the
              appropriate category (if any).</span></li>
        </ul>

        <p class="content">Without prejudice to the rest of these Terms and Angsaku&rsquo;s
          policies, you warrant, in respect of each item which you offer for sale on the Services, that:
        </p>
        <ul class="content">
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">You are the owner of the item, and the item is not
              stolen.</span></li>
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">The item is not counterfeit and does not infringe upon any
              third party&apos;s copyright, patent, trademark, trade secret or other proprietary or intellectual
              property
              rights.</span></li>
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">The sale of the item complies with all laws and regulations
              which apply to that item.</span></li>
          <li><span style="font-family:Symbol;font-size:10.0000pt;">&middot;&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;">The item is not dangerous, hazardous or subject to a recall
              by a government or manufacturer.</span></li>
        </ul>

        <p class="content">There are certain items or services that are not allowed on
          Angsaku at all. They may not necessarily be illegal, however, they aren&apos;t in the spirit of Angsaku. These
          include:
        </p>
        <ul class="content">
          <li><span style="font-size:11.0000pt;">1.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Alcohol, Drugs &amp; Tobacco</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Alcohol</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">E-Vaporizers (E-Cigarettes) and related products</span>
              </li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Tobacco products and other smokeable products</span>
              </li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Items that resemble smoking products or promote
                  smoking</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Drugs and drug paraphernalia</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">2.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Adult &amp; Mature
              Content</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Pornography and adult material including, but not
                  limited to, pornographic books, magazines, videos, pictures and sex enhancement products</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Used undergarments and intimate items</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Adult industry services and jobs, including escort
                  services</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">3.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Animals &amp; Animal
              Products</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Live animals and insects</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Items made from animals, including, but not limited to,
                  endangered species and exotic animals</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Human beings, human remains and body parts</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">4.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Counterfeit &amp;
              Replica Items</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Counterfeit items</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Items that infringe copyright laws including, but not
                  limited to, unauthorized sales of eBooks</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">5.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Weapons</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Knives, Swords and Hand Weapons, unless used for
                  non-lethal sports or display/dress-up purposes</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Firearms, Imitation Firearms, Ammunition and
                  Explosives, unless determined to be toy weapons/ammunition or used for sporting purposes</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Others (e.g. Tasers)</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">6.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Illegal Items or
              Encouraging Illegal Activity</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Stolen or illegal goods</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Items or listings that promote, support or seek to
                  engage users in illegal activity</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">7.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Medical &amp;
              Healthcare Items</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Health supplements</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Contact lenses and other optometry products</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Medicine, medicinal instruments and items with
                  objectionable medical claims</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Injectable health/cosmetic substances</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">8.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Offensive
              Materials</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Content that promotes, supports or glorifies acts of
                  violence</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Content that seeks to shame others and/or reveal
                  personal information of others</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Content that promotes, supports or glorifies hatred and
                  seeks to discriminate and/or demean on the basis of race, religion, gender, gender identity,
                  disability
                  or sexual orientation</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">9.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Consumable Items</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Items that fall under any of the following are strictly
                  prohibited:</span>
                <ul style="list-style-type: undefined;">
                  <li><span style="font-size:11.0000pt;">1.&nbsp;&nbsp;</span><span style="font-size:11.0000pt;">Food
                      with
                      medicinal or health claims</span></li>
                  <li><span style="font-size:11.0000pt;">2.&nbsp;&nbsp;</span><span style="font-size:11.0000pt;">Food
                      containing
                      prohibited substances</span></li>
                  <li><span style="font-size:11.0000pt;">3.&nbsp;&nbsp;</span><span style="font-size:11.0000pt;">Food
                      containing
                      substances in excess of permitted
                      proportions</span></li>
                  <li><span style="font-size:11.0000pt;">4.&nbsp;&nbsp;</span><span style="font-size:11.0000pt;">Food
                      unfit for
                      human consumption</span></li>
                  <li><span style="font-size:11.0000pt;">5.&nbsp;&nbsp;</span><span
                      style="font-size:11.0000pt;">Perishable Food
                      (e.g. Fruits and Vegetables, Dairy
                      Products, Frozen Meat)</span></li>
                </ul>
              </li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">For all other consumable items, they must adhere to all
                  of the following criteria:</span>
                <ul style="list-style-type: undefined;">
                  <li><span style="font-size:11.0000pt;">6.&nbsp;&nbsp;</span><span style="font-size:11.0000pt;">Product
                      is
                      sealed or packaged</span></li>
                  <li><span style="font-size:11.0000pt;">7.&nbsp;&nbsp;</span><span style="font-size:11.0000pt;">Product
                      has
                      food label(s) with an ingredient list
                      that is readable and accurate</span></li>
                  <li><span style="font-size:11.0000pt;">8.&nbsp;&nbsp;</span><span
                      style="font-size:11.0000pt;">Expiration date
                      is clearly printed and stated on
                      the product packaging</span></li>
                </ul>
              </li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Controlled goods that are affixed with the SAFETY
                  Mark</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Licensed medical and healthcare services</span></li>
            </ul>
          </li>
          <li><span style="font-size:11.0000pt;">10.&nbsp;&nbsp;</span><span
              style="font-size:11.0000pt;font-weight: 600;">Others</span>
            <ul style="list-style-type: undefined;">
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Recalled items</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Moneylender services</span></li>
              <li><span style="font-family:Symbol;font-size:11.0000pt;">&middot;&nbsp;&nbsp;</span><span
                  style="font-size:11.0000pt;">Listings promoting Multi-Level-Marketing programs,
                  Get-Rich-Quick schemes, pyramid selling schemes and Commission-only scheme</span></li>
            </ul>
          </li>
        </ul>

        <p class="content">In communicating with a user through the Services for the purchase, sale or rental of an item
          (“Transaction”), you may obtain personal information of that user, such as their email address, phone number
          and mailing address. Without obtaining prior permission of the user, you shall use such information solely for
          the purpose of the Transaction.
        </p>

        <p class="content">Notwithstanding the foregoing, as Angsaku is not involved in Transactions which are
          considered solely between users, Angsaku cannot ensure that a user (whether as a buyer, renter or seller)
          would follow through and complete a Transaction including the return of rented items and/or its condition upon
          return.
        </p>

        <p class="content">If you are in dispute with a user of our Services, you are encouraged to contact that user to
          resolve the situation amicably. Angsaku is not under any obligation to resolve any dispute between users. You
          release Angsaku from any claims, demands, and damage arising out of your disputes with users of our Services.
        </p>
      </div>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Promotion</p>
        <p class="content">We may offer you a Promotion from time to time. We may notify you of any Promotion offered to
          you either through any of our Platform/Mobile App or other method. The terms of each Promotion will either be
          set out in a specific term for the relevant Services, in advertising material, or you will be advised
          separately in writing or in any other method. If you participate the Promotion, the terms of the Promotion
          will prevail to the extent that the terms of the Promotion are inconsistent with the Terms, otherwise, the
          Terms of Use shall continue to apply. After the Promotion expires, the Promotion will end.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Billings, Funds, Fees and Payments</p>
        <p class="content">Angsaku uses third party payment gateway (the “Payment Gateway”) to verify identity, secure
          and protect your credit/debit card, ewallet, online bank transfer/remittance information and process payments
          made by means of this Services. You can find more information about the Payment Gateway, including their Terms
          of Service, security and privacy policy at third party payment gateway’s website.
        </p>

        <p class="content">All utilities payments, funds (for example, sinking fund), facilities booking fee, deposits
          and online forms application fees (“Resident/Condo Service Payments”), goes directly to Property Manager’s
          payment account. At no time does Angsaku handle or process any Service Payments. The Services only provide a
          platform to enable users to make Resident/Condo Service Payments directly to Property Manager’s using the
          Payment Gateway.
        </p>

        <p class="content">You undertake not to use the Payment Service in an unlawful or fraudulent manner. In
          particular, you shall not make any online payment using a credit/debit card, ewallet, online bank
          transfer/remittance or payment instrument other than your own or without the prior consent of the lawful
          holder thereof.
        </p>

        <p class="content">You agree to cooperate in relation to any financial crime screening that is required and to
          assist us in complying with any prevailing laws or regulations in place.
        </p>

        <p class="content">You shall be responsible to resolve any disputes with your credit/debit card, ewallet, online
          bank transfer/remittance company on your own effort.
        </p>

        <p class="content">Without prejudice to the other provisions of these Terms of Service, Angsaku shall not be
          responsible for any malfunction in any computer system, software or any Internet access service provider that
          may affect the accuracy or timeliness of the online transmission of payment instructions. Angsaku also shall
          not be responsible if any credit/debit card, ewallet, online bank transfer/remittance or other information
          provided is incorrect or if payment instructions are not given sufficiently in advance to allow for timely
          payment or if payment instructions cannot be carried out for any reason beyond our control.
        </p>

        <p class="content">Angsaku will not entertain any requests for payment errors, payment in excess and refund of
          payments made.
        </p>

        <p class="content">Angsaku reserves the right, at our sole discretion, without prior notice and without
          liability to any person, to reject any order or purchase without assigning any reason.
        </p>


        <p class="content">Billing Cycle. The fees and any other charges you may incur in connection with your use of
          the service, such as taxes and possible transaction fees, will be charged to your Payment Method on the
          specific billing date. The length of your billing cycle will depend on the type of subscription/bills that
          offered by Property Manager and third parties. In some cases, your payment date may change, for example if
          your Payment Method has not successfully settled or if your paid began on a day not contained in a given
          month. We may authorize your Payment Method in anticipation of charges through various methods.
        </p>


        <p class="content">Payment Methods. You authorize us to charge any Payment Method associated to your account in
          case your primary Payment Method is declined or no longer available to us for payment of your fees. You remain
          responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration,
          insufficient funds, or otherwise, we may suspend your access to the service until we have successfully charged
          a valid Payment Method. For some Payment Methods, the issuer may charge you certain fees, such as foreign
          transaction fees or other fees relating to the processing of your Payment Method. Local tax charges may vary
          depending on the Payment Method used. Check with your Payment Method service provider for details.
        </p>

        <p class="content">Updating your Payment Methods. You can update your Payment Methods. We may also update your
          Payment Methods using information provided by the payment service providers. Following any update, you
          authorize us to continue to charge the applicable Payment Method(s).
        </p>

        <p class="content">Changes to the fees/prices and plans. We may change our plans and the fees/prices of our
          service from time to time; we may also offer different terms, and the fees/prices for such may vary.
        </p>

        <p class="content">We may send you email and other communications related to your payments (regardless of any
          settings or preferences related to your account).
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Refund Policy</p>
        <p class="content">To the extent permitted by the applicable law, all payments are non-refundable and we do not
          provide refunds or credits for any partial paid service periods, unutilized credits, or unwatched service,
          including any redemption through a gift code or promotional code.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Rights You Grant</p>
        <p class="content">You have the full access your personal information to modify/delete and decline to the use of
          your Personal Information. Your Data Your Own Responsibility, We do not take control to your data no matter
          what circumferences.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">License to Angsaku</p>
        <p class="content">By providing Content to the Service, you grant to Angsaku royalty-free, sublicensable and
          transferable license to use that Content (including to reproduce, distribute, prepare derivative works,
          display and perform it) in connection with the Service and Angsaku (and its successors’ and Affiliates’)
          business, including for the purpose of promoting and redistributing part or all of the Services.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">License to Other Users</p>
        <p class="content">You also grant each other user of the Services a worldwide, non-exclusive, royalty-free
          license to access your Content through the Service, and to use that Content, including to reproduce,
          distribute, prepare derivative works, display, and perform it, only as enabled by a feature of the Services
          (such as video playback or embeds). For clarity, this license does not grant any rights or permissions for a
          user to make use of your Content independent of the Services.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Duration of License</p>
        <p class="content">The licenses granted by you continue for a commercially reasonable period of time after you
          remove or delete your Content from the Service. You understand and agree, however, that Angsaku may retain,
          but not display, distribute, or perform, save copies of your videos that have been removed or deleted.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Removing Your Content</p>
        <p class="content">If you no longer wish to use the Angsaku services or remove your Content, you may delete the
          Content from the Service at any time.
        </p>

        <p class="content">Upon verification of your identity, we will respond to your request within a reasonable
          period of time, and in accordance with relevant law 60 days.
        </p>

      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Removal of Content By Angsaku</p>
        <p class="content">If Angsaku has discovered or received complaint on any acts against these Terms, Angsaku
          reserved the rights to delete or hide relevant information without prior notice, take action towards violator
          account including but not limited to warning, restrict or prohibit usage of certain or all services, ban such
          account until penalty has been cancelled and to announce regarding the penalties.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Copyright Protection</p>
        <p class="content">Angsaku is the intellectual property rights holder of BeanCow PLT. All copyrights,
          trademarks, patents, trade secrets and other intellectual properties along with its relevant information
          (including but not limited to text, image, voice clip, video, graphic, interface design, layout, relevant data
          or electronic file) are subject to the protection under law and regulations of Malaysia and international
          treaties, Angsaku owns the intellectual property right above except entitled rights of relevant holder
          according to law.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Reporting Unauthorized Content</p>
        <p class="content">Angsaku respects the privacy, rights to a safe online environment and intellectual property
          rights of others and we expect the same of our users.
        </p>

        <p class="content">If you would like to report any abuse or violation to the Service Terms of Service and/or
          Privacy Policies, you may contact us via email at <a href="mailto:support@thebeancow.com">support@angsaku.com</a>
        </p>

        <p class="content">Angsaku reserves the right to suspend or terminate your account and/or your access to the
          Services if there are repeat complaints against you of intellectual property infringement.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Account Suspension & Termination</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Terminations by You</p>
        <p class="content">You may delete your account and withdraw from using the Services, at any time.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Terminations and Suspensions by Angsaku for Cause</p>
        <p class="content">Angsaku may suspend or delete your account without giving prior notice to you if 1) we believe that you are violating or has violated the Terms, or 2) the account has no activity for 365 days. Each account in the Services is for the exclusive use and belongs solely to the owner of the account. You must not transfer or lend your account to any third party nor may your account be inherited by any third party. However, you may still apply for appeal if the conduct was made incorrectly. Upon verification of your identity, we will respond to your request within a reasonable period of time, and in accordance with relevant law 60 days. Angsaku reserves the right to terminate or suspend your account if we believe that you are using proxy IPs (Internet Protocol addresses) in order to attempt to hide the use of multiple registration accounts, or pretends to be a user, or disrupts the smooth operation of this Services in any way or disrupts or annoys other users of this services.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Notice for Termination or Suspension</p>
        <p class="content">We will notify you with the reason for termination or suspension by Angsaku unless we reasonably believe that to do so: (a) would violate the law or the direction of a legal enforcement authority, or would otherwise risk legal liability for Angsaku or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Services; or (c) would cause harm to any user, other third party, Angsaku or our Affiliates. Where Angsaku is terminating your access for service changes, where reasonably possible, you will be provided with sufficient time to export your Content from the Services.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Effect of Account Suspension or Termination</p>
        <p class="content">If your account is terminated or your account’s access to the Services is restricted, you may continue using certain aspects of the Service (such as viewing only) without an account, and this Agreement will continue to apply to such use. If you believe your account has been terminated in error, you can appeal using this form.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">About Software in the Service</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Downloadable Software</p>
        <p class="content">When the Services require or include downloadable software, you give permission for that software to update automatically on your device once a new version or feature is available, subject to your device settings. Unless that software is governed by additional terms which provide a license, Angsaku gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by Angsaku as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by Angsaku, in the manner permitted by this Agreement. You are not allowed to copy, modify, distribute, sell, or lease any part of the software, or to reverse-engineer or attempt to extract the source code of that software, unless laws prohibit these restrictions or you have Angsaku’s written permission.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Open Source</p>
        <p class="content">Some software used in our Services may be provided under an open source license that we make accessible to you. There may be provisions in open source license that purposely revoke some of these terms. Therefore, please ensure that you had read those licenses.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Other Legal Terms</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Warranty Disclaimer</p>
        <p class="content">YOU AGREED THAT YOUR USE OF ANGSAKU SERVICES SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, ANGSAKU, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND USERS USED THEREOF. ANGSAKU MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THIS SERVICE'S CONTENT AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM USER ACCESS TO AND USE OF OUR SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF COMPANY SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM COMPANY SERVICE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE THROUGH (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE THROUGH THE ACTIONS OF ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONSIN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA ANGSAKU SERVICE. ANGSAKU DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH ANGSAKU SERVICE OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY USER STATUS SUBMISSION OR OTHER ADVERTISING, AND ANGSAKU WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Limitation of Liability</p>
        <p class="content">Angsaku will not be liable to you for any direct, indirect, incidental, special, consequential or exemplary damages (including damages for loss of profits, goodwill, use or data), even if a party has been advised of the possibility of such damages. Further, Angsaku will not be responsible for any compensation, reimbursement, or damages arising in connection with: your inability to use Angsaku services; the cost of procurement of substitute goods or services; any investments, expenditures, or commitments by you in connection with this Agreement or your use of or access to Angsaku services; or any unauthorized access to, alteration of, or the deletion, destruction, damages, loss or failure to store any of your content or other data.
        </p>

        <p class="content">TO THE EXTENT PERMITTED BY APPLICABLE LAW, ANGSAKU AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICES IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT ANGSAKU HAS PAID TO YOU FROM YOUR USE OF THE SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO ANGSAKU, OF THE CLAIM AND (B) RM 500.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Indemnity</p>
        <p class="content">To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Angsaku, its Affiliates, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Service; (ii) your violation of any term of this Agreement; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive this Agreement and your use of the Services.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Notice</p>
        <p class="content">You can generally contact our Customer Service Support. However, if you wish to lodge a complaint, dispute about the Platform and/or the Services, or serve us a demand letter, legal process, or other communication relating to that (collectively known as “Formal Notice”), you should address a copy of the Formal Notice to our office. The Formal Notice can be delivered by hand, courier and/or prepaid registered post our registered address.
        </p>

        <p class="content">We can serve a Formal Notice or other communication to you by electronic email, delivered by hand, courier, prepaid ordinary post, registered post (not being AR Registered), and/or facsimile to the address you provided to us. We can also serve you a Formal Notice, or other communication to your last known address in our record. Formal Notice or other communication shall be deemed effective: - (a) if by electronic email or hand deliver, on the day of delivery; (b) if by prepaid registered post, five (5) days after it was duly posted; (c) if by courier, 1 day after dispatch; or (d) if by facsimile, on the day of transmission provided that the transmission report from the sender’s facsimile machine confirms that transmission is in full and without error. You agree that in the event that any action is begun in the courts in Malaysia in respect of your use of the Platform and/or Services, the legal process and other documents may be served by posting the documents to you by registered post (not being AR Registered Post) at the address you provided to us or to your last known address in our records and such service shall on the fifth (5) day after posting, be deemed to be good and sufficient services of such legal process or documents.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Third-Party Links</p>
        <p class="content">The Service may include links to third-party website or any online services that are not belongs or controlled by Angsaku.
        </p>

        <p class="content">You acknowledge and agree that despite Angsaku reasonable efforts, it is impossible for us to guard against all circumstances that may affect your access to, and usage of, Angsaku services, or which may result in data loss. You agree that you are responsible for properly configuring and using any software accessed while utilizing Angsaku services. You also agree that you are responsible for taking your own steps to maintain appropriate security, protection and backup of Your Content.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">About this Agreement</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Modifying this Agreement</p>
        <p class="content">We reserve the right to modify this Agreement when it is necessary to legal, regulatory, or any security reasons. We may provide reasonable advance notice on any modifications made to this Agreement and the opportunity to review them. However, there are some case where it is legally permitted or any modifications indicate new features of the Services made for legal reasons may take effective immediately without prior notice.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Continuation of this Agreement</p>
        <p class="content">If your use of the Services ends, the following terms of this Agreement will carry on to apply to you: “Other Legal Terms”, “About This Agreement”, and the licenses granted by you will continue as described under “Duration of License”
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Severance</p>
        <p class="content">If it turns out that a particular term of this Agreement is not enforceable for any reason, this will not affect any other terms.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">No Waiver</p>
        <p class="content">If you fail to comply with this Agreement and we do not take immediate action, this does not mean that we are giving up any rights that we may have the right to take action in the future.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Interpretation</p>
        <p class="content">In these terms, “include” or “including” means “including but not limited to,” and any examples we give are for explanatory purposes.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Miscellaneous</p>
        <p class="content">You and Angsaku are independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by these Terms.
        </p>

        <p class="content">If any provision of these Terms is held unenforceable, then such provision will be modified to reflect the parties' intention. All remaining provisions of these Terms shall remain in full force and effect.
        </p>

        <p class="content">Except as provided herein, any failure by Angsaku to exercise a right or require performance of an obligation in these Terms shall not affect Amgsaku’s ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach of these Terms constitute a waiver of any subsequent breach.
        </p>

        <p class="content">You shall not assign any rights or delegate any obligations herein without our prior written consent and any attempted assignment or delegation in contravention of this provision shall be null and void and of no force or effect.
        </p>

        <p class="content">These Terms constitute the entire agreement between you and Angsaku and supersede all prior or contemporaneous understandings and/or agreements between you and Angsaku.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Governing Law</p>
        <p class="content">For all disputes, whether pursued in court or with the relevant regulatory body, you must first give us an opportunity to resolve your claim by sending a written description of your claim addressed to us in the manner set out in this Agreement. (b) Upon receipt of the written notice, we will use our reasonable endeavour to resolve the dispute. We may, as and when we deem fit, conduct any investigation regarding the disputed amount. The result of the findings is deemed to be final and conclusive and binding on you and shall not be questioned by you on any account. (c) You agree that any cause of action arising out of or related to the access and/or use of the Platform and/or Services must commence within twelve (12) months after the cause of action arose; otherwise, such cause of action is permanently barred.
        </p>

        <p class="content">Should a dispute arise under this Agreement, you agree that this Agreement shall be governed by and construed in accordance with the laws of the Country of Malaysia, without regard to its conflict of laws rules.
        </p>

        <p class="content">Should you have any questions concerning the Agreement, the Platform, the Services, or any Terms of Service found within Website, please contact our Customer Service Support.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
      <p class="content">Effective as of September 20, 2020
      </p>
      </div>

    </div>
  </div>
</div>

<!-- Footer Section Start -->
<app-footer></app-footer>
<!-- Footer Section End -->

<a href="#" class="back-to-top">
    <i class="lni-arrow-up"></i>
  </a>

<!-- Preloader -->
<div id="preloader">
  <div class="loader" id="loader-1"></div>
</div>
