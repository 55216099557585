<!-- Navbar Start -->
<app-navbar>
</app-navbar>
<!-- Navbar End -->

<div id="hero-area" class="hero-area-bg">
  <div class="container">
    <div class="">
      <h1>Privacy Policy</h1>
      <p>&nbsp;</p>
      <div class="section-header text-center">
        <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">This Privacy Policy ("Policy") describes how
          Angsaku will use your Personal Data.</h2>
        <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Collection of Personal Data</p>
        <p class="content">"Personal Data" means information about you, from which you are identifiable, including but
          not limited to your name, identification card number, birth certificate number, passport number, nationality,
          address, telephone number, fax number, bank details, credit card details, race, gender, date of birth, marital
          status, any information about you which you have provided to Angsaku in registration forms, application forms
          or any other similar forms and/or any information about you that has been or may be collected, stored, used
          and processed by Angsaku from time to time and includes sensitive personal data such as data relating to
          health, religious or other similar beliefs. To upload an image for further analysis, you can either from your album or 
          capture a new photo using your device's camera. The selected images shall be used to provide additional information regarding 
          the identified defect. This additional information will help us in analyzing and addressing the specific issue.
        </p>
        <p class="content">By providing your Personal Data, you agree that that Angsaku may use and process your
          Personal Data for businesses and activities including but not limited to:
        </p>

      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Purpose</p>
        <p class="content">We collect information to provide better services to all our users — from figuring out basic
          stuff like which language you speak, to more complex things like which ads you’ll find most useful, the people
          who matter most to you online, or which content you might like. We do store the information we collect with
          unique identifiers tied to the browser, application, or device you’re using. This helps us do things like
          maintain your language preferences across browsing sessions and serve you better.
        </p>
        <p class="content">In addition, device type and settings, operating system, mobile network information including
          carrier name and phone number, and application version number are collected when you use our apps or browse
          our websites. We also collect information about the interaction of your apps, browsers, and devices with our
          services, including IP address, crash reports, system activity, and the date, time, and referrer URL of your
          request.
        </p>

        <p class="content">By uploading the image, you understand and agree that the uploaded photos provided shall be used
           for processing and performing analysis in report.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Develop new services</p>
        <p class="content">We use the information we collect in existing services to help us develop new ones.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Maintain & improve our services</p>
        <p class="content">We also use your information to ensure our services are working as intended, such as tracking
          outages or troubleshooting issues that you report to us. And we use your information to make improvements to
          our services — for example, understanding which search terms are most frequently misspelled helps us improve
          spell-check features used across our services.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Provide personalized services, including content and ads</p>
        <p class="content">We use the information we collect to customize our services for you, including providing
          recommendations, personalized content, and customized search results.
        </p>

        <p class="content">Depending on your settings, we may also show you personalized ads based on your interests.
          For example, if you search for “Car,” you may see an ad for automotive.
        </p>

        <p class="content">We don’t share information that personally identifies you with advertisers, such as your name
          or email, unless you ask us to.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Measure performance</p>
        <p class="content">We use data for analytics and measurement to understand how our services are used. For
          example, we analyze data about your visits to our sites to do things like optimize product design. And we also
          use data about the ads you interact with to help advertisers understand the performance of their ad campaigns.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Communicate with you</p>
        <p class="content">We use information we collect, like your email address, to interact with you directly. For
          example, we may send you a notification if we have new business promotion activities. Or we may let you know
          about upcoming changes or improvements to our services.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Protect our users, and the public</p>
        <p class="content">We use information to help improve the safety and reliability of our services. This includes
          detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm
          our users, or the public.
        </p>

        <p class="content">We use different technologies to process your information for these purposes. We may use
          automated systems that analyze your content to provide you with things like customized search results,
          personalized ads, or other features tailored to how you use our services. And we analyze your content to help
          us detect abuse such as spam, malware, and illegal content. We also use algorithms to recognize patterns in
          data.
        </p>

        <p class="content">We may combine the information we collect among our services and across your devices for the
          purposes described above.
        </p>

        <p class="content">We’ll ask for your consent before using your information for a purpose that isn’t covered in
          this Privacy Policy
        </p>

        <p>&nbsp;</p>

        <p class="content">Where you are a customer of the services provided by Angsaku:
        </p>

        <p class="content"><span style="font-size:11.0000pt;">1.&nbsp;</span><span style="font-size:11.0000pt;">To
            fulfill your request of any services that Angsaku provides.</span></p>
        <p class="content"><span style="font-size:11.0000pt;">2.&nbsp;</span><span style="font-size:11.0000pt;">To
            process your registration application and deliver any of Angsaku&rsquo;s services to you; where you have
            requested to download and use the Angsaku Software/App to process your request and to deliver the app to
            you.</span></p>
        <p class="content"><span style="font-size:11.0000pt;">3.&nbsp;</span><span style="font-size:11.0000pt;">To
            process your participation in any events, activities or promotions that we consider suitable.&nbsp;</span>
        </p>
        <p class="content"><span style="font-size:11.0000pt;">4.&nbsp;</span><span style="font-size:11.0000pt;">To
            validate and proceed with any payments related to any of our products or services you have
            requested.&nbsp;</span></p>
        <p class="content"><span style="font-size:11.0000pt;">5.&nbsp;</span><span style="font-size:11.0000pt;">To
            develop, improve and provide products and services that you may require.</span></p>

        <p>&nbsp;</p>

        <p class="content">Where you are a service provider:
        </p>
        <p class="content"><span style="font-size:11.0000pt;">1.&nbsp;</span><span style="font-size:11.0000pt;">To
            communication with you to provide services or products.</span></p>
        <p class="content"><span style="font-size:11.0000pt;">2.&nbsp;</span><span style="font-size:11.0000pt;">To
            process payments related to any products or services that you have provided.</span></p>

        <p>&nbsp;</p>

        <p class="content">Where you are a user of any feature of the App:
        </p>
        <p class="content"><span style="font-size:11.0000pt;">1.&nbsp;</span><span style="font-size:11.0000pt;">To send
            you alerts, newsletters, updates, promotional materials and other contents deemed suitable by
            Angsaku.</span></p>
        <p class="content"><span style="font-size:11.0000pt;">2.&nbsp;</span><span style="font-size:11.0000pt;">To
            notify and invite you to events organized by Angsaku, its partners, sponsors or advertisers.</span></p>
        <p class="content"><span style="font-size:11.0000pt;">3.&nbsp;</span><span style="font-size:11.0000pt;">3.To
            share your Personal Data amongst associate companies as well as with its agents, vendors, suppliers,
            partners and any other related party deemed suitable by Angsaku. </span></p>

        <p>&nbsp;</p>

        <p class="content">By post, telephone call, Short Message Service (SMS), by hand and/or by email.
        </p>

        <p class="content"><span style="font-size:11.0000pt;">1.&nbsp;</span><span style="font-size:11.0000pt;">Terms
            you search for</span></p>
        <p class="content"><span style="font-size:11.0000pt;">2.&nbsp;</span><span style="font-size:11.0000pt;">Videos
            you watch</span></p>
        <p class="content"><span style="font-size:11.0000pt;">3.&nbsp;</span><span style="font-size:11.0000pt;">Views
            and interactions with content and ads</span></p>
        <p class="content"><span style="font-size:11.0000pt;">4.&nbsp;</span><span style="font-size:11.0000pt;">Voice
            and audio information when you use audio features</span></p>
        <p class="content"><span style="font-size:11.0000pt;">5.&nbsp;</span><span style="font-size:11.0000pt;">Purchase
            activity</span></p>
        <p class="content"><span style="font-size:11.0000pt;">6.&nbsp;</span><span style="font-size:11.0000pt;">People
            with whom you communicate or share content</span></p>
        <p class="content"><span style="font-size:11.0000pt;">7.&nbsp;</span><span style="font-size:11.0000pt;">Activity
            on third-party sites and apps that use our services</span></p>
        <p class="content"><span style="font-size:11.0000pt;">8.&nbsp;</span><span style="font-size:11.0000pt;">Your
            location information</span></p>

        <p>&nbsp;</p>

        <p class="content">We collect information about your location when you use our services, which helps us offer
          features like driving directions for your weekend getaway or showtimes for videos playing near you.
        </p>

        <p>&nbsp;</p>

        <p class="content">Your location can be determined with varying degrees of accuracy by:
        </p>

        <p class="content"><span style="font-size:11.0000pt;">1.&nbsp;</span><span
            style="font-size:11.0000pt;">GPS</span></p>
        <p class="content"><span style="font-size:11.0000pt;">2.&nbsp;</span><span style="font-size:11.0000pt;">IP
            address</span></p>
        <p class="content"><span style="font-size:11.0000pt;">3.&nbsp;</span><span style="font-size:11.0000pt;">Sensor
            data from your device</span></p>
        <p class="content"><span style="font-size:11.0000pt;">4.&nbsp;</span><span
            style="font-size:11.0000pt;">Information about things near your device, such as Wi-Fi access points, cell
            towers, and Bluetooth-enabled devices</span></p>

        <p>&nbsp;</p>

        <p class="content">The types of location data we collect depend in part on your device and account settings. For
          example, you can turn your device’s location on or off using the device’s settings app.
        </p>
        <p class="content">In some circumstances, We may also collect information about you from trusted partners,
          including marketing partners who provide us with information about potential customers of our business
          services, and security partners who provide us with information to protect against abuse. We also receive
          information from advertisers to provide advertising and research services on their behalf.
        </p>

        <p class="content">We use various technologies to collect and store information, including cookies, pixel tags,
          local storage, such as browser web storage or application data caches, databases, and server logs.
        </p>

        <p class="content">You agree and consent to Angsaku using your Personal Data for the purposes mentioned above.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">For revocation of consent</p>
        <p class="content">If you wish to u revoke the consent that Angsaku has obtained from you for the Purpose(s)
          stated above, please email Angsaku customer service at <a
            href="mailto:support@angsaku.com">support@angsaku.com</a> or call us (+60) 13 610 3488 at and state your wish to revoke
          your consent.
        </p>
        <p class="content">Delete your information
        </p>

        <p class="content">RETAINING YOUR INFORMATION
        </p>

        <p class="content">We retain the data we collect for different periods of time depending on what it is, how we
          use it, and how you configure your settings:
        </p>

        <p class="content">Some data you can delete whenever you like, such as the content you create or upload. You can
          also delete activity information saved in your account, or choose to have it deleted automatically after a set
          period of time.
        </p>

        <p class="content">Other data is deleted or anonymized automatically after a set period of time, such as
          advertising data in server logs.
        </p>

        <p class="content">And some data we retain for longer periods of time when necessary for legitimate business or
          legal purposes, such as security, fraud and abuse prevention, or financial record-keeping.
        </p>

        <p class="content">When you delete data, we follow a deletion process to make sure that your data is safely and
          completely removed from our servers or retained only in anonymized form. We try to ensure that our services
          protect information from accidental or malicious deletion. Because of this, there may be delays between when
          you delete something and when copies are deleted from our active and backup systems.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Transfer of Personal Data & Disclosure to Third Parties</p>
        <p class="content">Your Personal Data may be transferred to, stored, used and processed in jurisdictions other
          than your home nation or in the jurisdictions you are present while using any of the services provided by
          Angsaku, to companies affiliated with Angsaku which may be located outside of your home country. By agreeing,
          you consent to the transfer of your personal data as described.
        </p>
        <p class="content">For legal reasons
        </p>

        <p class="content">We will share personal information outside of Angsaku if we have a good-faith belief that
          access, use, preservation, or disclosure of the information is reasonably necessary to:
        </p>

        <p class="content">Meet any applicable law, regulation, legal process, or enforceable governmental request. We
          share information about the number and type of requests we receive from governments in our Transparency
          Report.
        </p>

        <p class="content">Enforce applicable Terms of Service, including investigation of potential violations.
        </p>

        <p class="content">Detect, prevent, or otherwise address fraud, security, or technical issues.
        </p>

        <p class="content">Protect against harm to the rights, property or safety of Angsaku, our users, or the public
          as required or permitted by law.
        </p>

        <p class="content">We may share non-personally identifiable information publicly and with our partners — like
          publishers, advertisers, developers, or rights holders. For example, we share information publicly to show
          trends about the general use of our services. We also allow specific partners to collect information from your
          browser or device for advertising and measurement purposes using their own cookies or similar technologies.
        </p>

        <p class="content">If Angsaku is involved in a merger, acquisition, or sale of assets, we’ll continue to ensure
          the confidentiality of your personal information and give affected users notice before personal information is
          transferred or becomes subject to a different privacy policy.
        </p>

        <p class="content">Your Personal Data may be transferred, accessed or disclosed to third parties for the
          Purpose(s) mentioned above.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Personal Information from Minors and Other Individuals</p>
        <p class="content">As a parent or legal guardian, please do not allow minors (individuals under 13 years of age)
          under your care to provide Personal Data to Angsaku. In the event that such Personal Data is provided to
          Angsaku, you hereby consent to the processing of the minor’s Personal Data and personally accept and agree to
          be bound by this Policy and take responsibility for his or her action.
        </p>
        <p class="content">In the case that you may have provided personal data relating to other individuals (such as
          your spouse, family members or friends) and in such case you represent and warrant that you are authorized to
          provide their personal data to Angsaku and you have obtained their consent for their personal data be
          processed and used in the manner as set forth in this Policy.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Personal Data Security and Protection</p>
        <p class="content">We build security into our services to protect your information
        </p>
        <p class="content">All our products are built with strong security features that continuously protect your
          information. The insights we gain from maintaining our services help us detect and automatically block
          security threats from ever reaching you. And if we do detect something risky that we think you should know
          about, we’ll notify you and help guide you through steps to stay better protected.
        </p>
        <p class="content">We work hard to protect you from unauthorized access, alteration, disclosure, or destruction
          of information we hold, including:
        </p>
        <p class="content">We use encryption to keep your data private while in transit.
        </p>
        <p class="content">We offer a range of security features, like Safe Browsing, Security Checkup, and 2 Step
          Verification to help you protect your account
        </p>
        <p class="content">We review our information collection, storage, and processing practices, including physical
          security measures, to prevent unauthorized access to our systems
        </p>
        <p class="content">We restrict access to personal information to our employees, contractors, and agents who need
          that information in order to process it. Anyone with this access is subject to strict contractual
          confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
        </p>
      </div>

      <p>&nbsp;</p>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Acknowledgement and Consent</p>
        <p class="content">This Privacy Policy applies to all of the services offered by Angsaku and its affiliates, and
          services offered on third-party sites, such as advertising services. This Privacy Policy doesn’t apply to
          services that have separate privacy policies that do not incorporate this Privacy Policy.
        </p>
        <p class="content">By communicating with Angsaku, using Angsaku’s services, purchasing products from Angsaku or
          by virtue of your engagement with Angsaku, you acknowledge that you have read and understood this Policy and
          agree and consent to the use, processing and transfer of your Personal Data by Angsaku as described in this
          Policy.
        </p>
        <p class="content">Angsaku shall have the right to modify, update or amend the terms of this Policy at any time
          by placing the updated Policy on the Websites. By continuing to communicate with Angsaku, by continuing to use
          Angsaku’s services, purchasing products from Angsaku or by your continued engagement with Angsaku following
          the modifications, updates or amendments to this Policy, such actions shall signify your acceptance of such
          modifications, updates or amendments. We change this Privacy Policy from time to time. We will not reduce your
          rights under this Privacy Policy without your explicit consent. We always indicate the date the last changes
          were published and we offer access to archived versions for your review. If changes are significant, we’ll
          provide a more prominent Policy (including, for certain services, email notification of Privacy Policy
          changes).
        </p>
        <p class="content">You also hereby agree that Angsaku shall not be held responsible or liable for any loss or
          damage of any kind incurred as a result of the use of any of our products or services.
        </p>
      </div>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="title">Compliance & Cooperation with Regulators</p>
        <p class="content">We regularly review this Privacy Policy and make sure that we process your information in
          ways that comply with it.
        </p>
      </div>

      <div class="wow fadeInDown" data-wow-delay="0.3s">
        <p class="content">Effective as of Jan 20, 2021
        </p>
      </div>

    </div>
  </div>
</div>

<!-- Footer Section Start -->
<app-footer></app-footer>
<!-- Footer Section End -->

<a href="#" class="back-to-top">
  <i class="lni-arrow-up"></i>
</a>

<!-- Preloader -->
<div id="preloader">
  <div class="loader" id="loader-1"></div>
</div>
