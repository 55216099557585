import { Routes } from "@angular/router";
import { AppPageComponent } from "./app-page/app-page.component";
import { DeeplinkComponent } from "./deeplink/deeplink.component";
import { DownloadComponent } from "./download/download.component";

import { LandingPageComponent } from "./landing-page.component";
import { PolicyComponent } from "./policy/policy.component";
import { TosComponent } from "./tos/tos.component";
import { YcRefundPolicyComponent } from "./yc-refund-policy/yc-refund-policy";

export const LandPageRoutes: Routes = [
    { path: '', redirectTo: 'main', pathMatch: 'full' },
    { path: 'main',component: LandingPageComponent },
    { path: 'tos',component: TosComponent },
    { path: 'policy',component: PolicyComponent },
    { path: 'download',component: DownloadComponent },
    { path: 'app',component: AppPageComponent },
    { path: 'app/applogin',component: AppPageComponent },
    { path: 'deeplink', component: DeeplinkComponent },
    { path: 'yc-refund-policy', component: YcRefundPolicyComponent }
]
