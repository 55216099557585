<footer id="footer" class="footer-area section-padding bg-gray" [ngStyle]="{'padding-top': removePTop == true ? '40px' : '150px'}">
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            <div class="widget">
              <h3 class="footer-logo"><img style="width: 20%;" src="assets/img/angsaku-logo2.png" alt=""> Angsaku</h3>
              <div class="textwidget">
                <p>Angsaku is designed as an ecosystem chain in which residents, communities, property manager and local businesses are
                  brought together to enjoy betterment and prosperity of the digital transformation initiatives that have been applied in
                  neighborhoods.</p>
              </div>
              <!-- <div class="social-icon">
                <a class="facebook" href="#"><i class="lni-facebook-filled"></i></a>
                <a class="twitter" href="#"><i class="lni-twitter-filled"></i></a>
                <a class="instagram" href="#"><i class="lni-instagram-filled"></i></a>
                <a class="linkedin" href="#"><i class="lni-linkedin-filled"></i></a>
              </div> -->
            </div>
          </div>
          <!-- <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <h3 class="footer-titel">Products</h3>
            <ul class="footer-link">
              <li><a href="#">Tracking</a></li>
              <li><a href="#">Application</a></li>
              <li><a href="#">Resource Planning</a></li>
              <li><a href="#">Enterprise</a></li>
              <li><a href="#">Employee Management</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <h3 class="footer-titel">Resources</h3>
            <ul class="footer-link">
              <li><a href="#">Payment Options</a></li>
              <li><a href="#">Fee Schedule</a></li>
              <li><a href="#">Getting Started</a></li>
              <li><a href="#">Identity Verification</a></li>
              <li><a href="#">Card Verification</a></li>
            </ul>
          </div> -->
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h3 class="footer-titel">Contact</h3>
            <ul class="address">
              <li>
                <a href="#"><i class="lni-map-marker"></i> BEANCOW PLT (202004000864)NO 72, JALAN MP18, TMN MERDEKA PERMAI, MELAKA, 75350, MALAYSIA.
                  </a>
              </li>
              <li>
                <a href="#"><i class="lni-phone-handset"></i> P: +60 13 610 3488</a>
              </li>
              <li>
                <a href="#"><i class="lni-envelope"></i> E: support@angsaku.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="copyright-content">
              <p>© Copyright | All rights reserved by ANGSAKU. &nbsp; 
                <a class="link-highlight" [href]="'/policy'">Privacy Policy</a>&nbsp; 
                <a class="link-highlight" [href]="'/tos'">Terms of Service</a>&nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
