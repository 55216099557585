import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-app-page',
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.scss']
})
export class AppPageComponent implements OnInit {
  deviceInfo : any
  qrImg : any


  qrLink: string = '';
  params: any = null;

  constructor(
    private _deviceService: DeviceDetectorService,
    private _route: ActivatedRoute
    ) {}

  ngOnInit() {
    this.deviceInfo = this._deviceService.getDeviceInfo();
    let deviceOS = this.deviceInfo.os.toLowerCase(); 
    this.qrImg = 'assets/img/qr-code/app.png';
    
    if (deviceOS == 'android'){
      window.location.href = 'https://play.google.com/store/apps/details?id=com.angsaku.app';
      this.qrImg = 'assets/img/qr-code/app_android.png';
    }
    if (deviceOS == 'ios'){
      window.location.href = 'https://apps.apple.com/my/app/angsaku/id1570231151';
    }
    // switch(deviceOS)
    // {
    //   case 'android':
    //     this.qrImg = 'assets/img/qr-code/playstore.png'
        
    //   break; 

    //   case 'ios':  
    //   this.qrImg = 'assets/img/qr-code/appstore.png'
    //   break;

    //   case 'windows':  
    //     this.qrImg = 'assets/img/qr-code/playstore.png'
    //   break;

    //   default:
    //     this.qrImg = 'assets/img/qr-code/playstore.png'
    // }

    if(window.location.search != '') {
      

      // const search = window.location.search;

      this.qrLink = `https://angsaku.com/deeplink${window.location.search}`;
    } else {
      this.qrLink = `https://angsaku.com/deeplink`
    }

  }



}
