<div class="container" >
    <div class="row justify-content-center" style="min-height: 100vh">
        <div class="col-md-6 col-12">
           <div class="d-flex flex-row h-100 justify-content-center align-items-center">
            <div class="d-flex flex-column">
                <div class="d-flex flex-row justify-content-center mb-5">
                    <img src="assets/img/angsaku-logo2.png" alt="assets/img/angsaku-logo2.png" width="100">
                </div>

                <div class="d-flex flex-row justify-content-center mb-3">
                    <button class="btn btn-primary" style="min-width: 180px" (click)="openApp()">
                        Open
                    </button>
                </div>

                <div class="d-flex flex-row mb-3">
                    <button class="btn btn-primary" style="min-width: 180px" (click)="download()">
                        Download App
                    </button>
                </div>
                
            </div>
           </div>
        </div>
    </div>
</div>