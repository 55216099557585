import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './module/error-page/error-page.component';
import { GeoLocationComponent } from './module/geo-location/geo-location.component';
import { LandingPageComponent } from './module/landing-page/landing-page.component';
import { LandPageModule } from './module/landing-page/landing-page.module';


export const AppRoutes: Routes = [

  // {
  //   path: 'land', loadChildren: () => import('./module/landing-page/landing-page.module').then(m => m.LandPageModule)
  // },
  {
    path: 'feature', loadChildren: () => import('./module/features/features.module').then(m => m.FeatureModule)
  },
  {
    path: 'nav', component: GeoLocationComponent
  },
  { path: 'main', loadChildren: () => import('./module/landing-page/landing-page.module').then(m => m.LandPageModule) },

  { path: 'apa', loadChildren: () => import('./module/apa-check/apa-check.module').then(m => m.ApaCheckModule) },

    // redirectTo: GlobalConstant.FIRST_PAGE_URL, pathMatch: 'full'

  { path: '', redirectTo: '/main', pathMatch: 'full'},
  {
    path: '**',
    component : ErrorPageComponent
  }
]

@NgModule({
  declarations:[],
  imports: [RouterModule.forRoot(AppRoutes),LandPageModule],
  exports: [RouterModule,LandingPageComponent]
})

export class AppRoutingModule {}
