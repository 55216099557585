import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  mainNavItem = [
    {name: "Home", link:"#hero-area"},
    {name: "Why Angsaku?", link:"#services"},
    {name: "Features", link:"#features"},
    // {name: "Team", link:"#team"},
    // {name: "Pricing", link:"#pricing"},
    {name: "ApaCheck", link:"#apaCheck"},
    {name: "Contact", link:"#contact"},
  ]

  subNavItem = [
    {name: "Terms of service", link:"/tos"},
    {name: "Privacy policy", link:"/policy"},
  ]

  navItems : Array<any> = [];
  constructor(private _router : Router) { }

  isMain : boolean = false;

  ngOnInit() {
    if(this._router.url == '/main'){
      this.navItems = this.mainNavItem;
      this.isMain = true;
    }else{
      this.navItems = this.subNavItem;
      this.isMain = false;
    }
  }

}
