import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    serverUrl: String = "https://api.sso.angsaku.com";
    serverUrl2: String = "https://apa.angsaku.com";

    constructor(private _http: HttpClient,private _globalService: GlobalService) { }

    addAngsakuContact = (form) => {
        return this._http.post(`${this.serverUrl}/angsaku-sso/contact/add`, form);
    }

    addApaContact = (form) => {
      return this._http.post(`${this.serverUrl2}/sso/drs-dms-sso/contact/add`, form);
    }
}
