<nav class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      
      <!-- assets/img/logo.png -->
      <a href="/main" class="navbar-brand"><img src="/assets/img/angsaku-logo2.png" alt=""></a>       
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="lni-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto w-100 justify-content-end clearfix">
          <li class="nav-item">
            <a class="nav-link" [href]="'/main'">
                Home
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Features</a>
            <div class="dropdown-menu" >
              <a class="dropdown-item" *ngFor="let items of dropDownItems" [href]="items.link" [ngClass]="{'active': this._router.url == items.link}">
                <span class="icon d-flex flex-row align-item-center">
                  <i class="material-icons" style="color: #787A40;" [ngStyle]="{'color': items.color}">
                    {{items.icon}}
                  </i>
                  {{items.name}}
                </span>
                </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
