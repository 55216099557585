<nav class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      
      <!-- assets/img/logo.png -->
      <a href="/main" class="navbar-brand"><img src="/assets/img/angsaku-logo2.png" alt=""></a> 
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="lni-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto w-100 justify-content-end clearfix">
          <li class="nav-item" *ngFor="let navitem of navItems" [ngClass]="{'active': this._router.url == navitem.link || navitem.link == '#hero-area'}">
            
            <ng-container *ngIf="isMain; else noMain">
                <a class="nav-link" [href]="navitem.link">
                    {{navitem.name}}
                  </a>
            </ng-container>

            <ng-template #noMain>
                <a class="nav-link" [href]="navitem.link">
                    {{navitem.name}}
                </a>
            </ng-template>
          
          </li>
        
        </ul>
      </div>
    </div>
  </nav>
