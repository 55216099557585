import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as data from "./features.json";

@Component({
  selector: 'app-feature-navbar',
  templateUrl: './feature_navbar.component.html',
  styleUrls: ['./feature_navbar.component.css']
})
export class FeatureNavbarComponent implements OnInit {
  dropDownItems = data['feature-items'];
  currentRoute = this._router.url;
  
  constructor(private _router : Router) { }

  ngOnInit() {
    console.log("this.router.url:",this._router.url);
  }

}
