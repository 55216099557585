import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  qrcode : any
  constructor(private _activatedRoute: ActivatedRoute, ) { 
    this._activatedRoute.queryParams.subscribe(async param => {
      this.qrcode = param.link 
    })
  }

  ngOnInit() {
    console.log('qrcode', this.qrcode)
  }

  // let deviceOS = this.deviceInfo.os.toLowerCase(); 
  // // alert(deviceOS);
  // switch(deviceOS)
  // {
  //   case 'android':
  //     setTimeout(function () {window.location.href="https://angsaku.com/app"; }, 25);
      
  //     window.location.href = "https://play.google.com/store/apps/details?id=com.angsaku.apphttps://angsaku.com/app";
  //   break; 

  //   case 'ios':  
  //     setTimeout(function () { window.location.href="https://angsaku.com/app"; }, 25);
  //     window.location.href = "https://apps.apple.com/my/app/beancow/id1521420587";
  //   break;

  //   case 'windows':  
  //     this._router.navigate(["download"], { queryParams: { devicetype: deviceOS, link : 'https://angsaku.com/app' } })
  //   break;

  //   default:
  //     this._router.navigate(["download"], { queryParams: { devicetype: deviceOS, link : 'https://angsaku.com/app' } })
  // }

}
