import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.css']
})
export class DeeplinkComponent implements OnInit {

  params: any = null;

  constructor(
    private _deviceService: DeviceDetectorService,
    private _router: Router,
    private _route: ActivatedRoute
  ) { 
  
  }

  ngOnInit(): void {
    if(window.location.search != '') {
      this.params = this._route.snapshot.queryParams;
    }

    const devicePc = this._deviceService.isDesktop();

    if(devicePc) {
      this._router.navigate(['app'], { queryParams: this.params, replaceUrl: true });
    }

  }

  openApp = () => {
    if(this.params) {
      window.location.href = `https://angsaku.com/app${window.location.search}`;
    } else {
      window.location.href = "https://angsaku.com/app";
    }
  }

  download = () => {
    window.location.href = "https://angsaku.com/app";
  }
}