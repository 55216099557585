import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { QRCodeModule } from "angularx-qrcode";
import { NgwWowModule } from "ngx-wow";
import { DebounceClickDirective } from "src/app/core/_helpers/debouce-click-directive";
import { MaterialModule } from "./material/material.module";
import { NgbBootstrapModule } from "./ngb-bootstrap/ngb-bootstrap.module";

@NgModule({
    declarations:[
        DebounceClickDirective
    ],
    imports:[
        CommonModule,
        FormsModule,
        NgbBootstrapModule,
        MaterialModule,
        HttpClientModule,
        ReactiveFormsModule,
        QRCodeModule,
    ],
    exports:[
        CommonModule,
        FormsModule,
        NgbBootstrapModule,
        MaterialModule,
        HttpClientModule,
        ReactiveFormsModule,
        DebounceClickDirective,
        QRCodeModule,
    ],
    providers:[]
})

export class SharedModule {}