
<div class="bg-darkgreen">
    <div class="container">
        <div class="row">

            <div class="col-md-12 col-sm-12 col-xs-12 text-center container-1">

                <div class="d-flex flex-column first-div">
                    <p class="title">Angsaku</p>
                    <p class="title">Scan me with mobile phone</p>

                    <qrcode class="mt-3" [qrdata]="qrcode" [size]="256" [level]="'H'" [version]="5"></qrcode>
                    <div class="w-100 d-flex justify-content-center">
                    <div id="owl-trans-iphone" class="iphone-bg">
                        <div class="item">
                            <img class="img-responsive centered" src="assets/img/angsaku-download-mobile2.jpg" alt="">
                        </div>
                    </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</div>
<app-footer [removePTop]="true"></app-footer>
