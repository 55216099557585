import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {
    public serverUrl: string;
    public recapthaSiteKey: string;
    constructor(private _http: HttpClient) {
    }

    getEnv() :Promise<any>  {

        const promise = this._http.get('./assets/env.json')
          .toPromise()
          .then(data => {
            Object.assign(this, data);
            this.serverUrl = data["server-url"];
            this.recapthaSiteKey = data["recaptcha-site-key"]
            return data;
          });
  
        return promise;
    }
   
}