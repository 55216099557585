import { NgModule } from '@angular/core';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
      NgbPaginationModule, 
      NgbAlertModule
  ],
})
export class NgbBootstrapModule {
}