import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-geo-location',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss']
})
export class GeoLocationComponent implements OnInit {
  latitude : any;
  longtitude : any;
  qrMapLink : any;
  devicePc : any
  constructor(private _activatedRoute: ActivatedRoute,
    private _deviceService: DeviceDetectorService) {
    this._activatedRoute.queryParams.subscribe( param => {
      if(param.lat != null || param.lon != null ){
        this.latitude = param.lat;
        this.longtitude = param.lon;
        this.devicePc = this._deviceService.isDesktop();
        this.qrMapLink = `https://www.google.com/maps/search/?api=1&query=${this.longtitude},${this.latitude}`;
      }else{
        window.location.href="**"
      }
    })
  }

  ngOnInit() {
  }
  openMap() {
      // window.location.href = `geo:${this.longtitude}, ${this.latitude}`;
      window.location.href = `https://www.google.com/maps/search/?api=1&query=${this.longtitude}, ${this.latitude}`;
  }

}
