import { Component, OnInit } from '@angular/core';
import packageJson from '../../../../package.json';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  version: string = packageJson.version;
  constructor() { }

  ngOnInit(): void {
  }

}
