import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/_services/api.service';
import { GlobalService } from 'src/app/core/_services/global.service';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  contactForm: FormGroup;
  siteKey: string;
  loading: boolean = false;
  focus: any;
  focus1: any;
  devicePc: any;
  deviceInfo: any;
  deviceOS: any;
  imgSrc: any;
  constructor(private _deviceService: DeviceDetectorService,
    private _fb: FormBuilder,
    private _router: Router,
    private _apiService: ApiService,
    private _snackBar: MatSnackBar,
    private _globalService: GlobalService,
    private _titleService: Title

  ) { }

  ngOnInit() {
    this.initForm()
    this.siteKey = "6Ldm0D0aAAAAAP8pBUBSsVlcYtVeVcsvvHjoSaKS";

    this._titleService.setTitle("Angsaku");


    if ((window as any).PasswordCredential || (window as any).FederatedCredential) {
      // Call navigator.credentials.get() to retrieve stored
      // PasswordCredentials or FederatedCredentials.
      // @ts-ignore
      // @ts-ignore
      // this.tryAutoSignIn();
    }
    this.devicePc = this._deviceService.isDesktop();
    this.deviceOS = this._deviceService.os.toLowerCase();

    this.deviceInfo = this._deviceService.getDeviceInfo();
    // console.log(this.devicePc);
    this.getSpy();
  }

  private tryAutoSignIn(): Observable<boolean> {
    // @ts-ignore
    if (!window.PasswordCredential) {
      return of(false);
    }

    // @ts-ignore
    return fromPromise(navigator.credentials.get({ password: true }))
      .pipe(
        mergeMap(cred => {
          if (cred) {
            // @ts-ignore
            return this.authService.login(cred.name, cred.password);
          }
          return of(false);
        }
        )
      );
  }

  initForm() {
    this.contactForm = this._fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.pattern(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z.]{2,}$/)]],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      recaptcha: ["", Validators.required],
    });
  }


  get f() {
    return this.contactForm.controls;
  }

  onContactSubmit() {

    if (!this.contactForm.valid) {

      return;
    }


    this.loading = true
    const formData = {
      email: this.f.email.value,
      message: this.f.message.value,
      name: this.f.name.value,
      subject: this.f.subject.value,
      reCaptcha: this.f.recaptcha.value
    }
    this._apiService.addAngsakuContact(formData).subscribe(res => {
      this._snackBar.open("Submitted", "OK", {
        duration: 2000,
        panelClass: ["snack-complete"] // Style on styles.css
      });
      this.contactForm.reset();
    }, error => {
      this._snackBar.open("Something went wrong", "OK", {
        duration: 2000,
        panelClass: ["snack-error"] // Style on styles.css
      });
      this.f.recaptcha.reset();
    })
  }

  private async storePassword(email: string, password: string): Promise<Credential | null> {
    // @ts-ignore
    if (!window.PasswordCredential) {
      return Promise.resolve(null);
    }
    // @ts-ignore
    const cred = new PasswordCredential({
      id: email,
      password: password,
      name: email
    });
    return navigator.credentials.store(cred);
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  errored(event) {
    // console.warn(`reCAPTCHA error encountered`);
  }

  getSpy = () => {
    $('[data-spy="scroll"]').each(function () {
      var $spy = $(this).scrollspy('refresh')
    })
  }

  downloadNow = () => {
    window.location.href = "https://angsaku.com/app";
  }

  downloadOfficial = () => {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/apk/angsaku_app.apk';
    link.download = 'angsaku.apk';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  naviTo = () => {
    window.location.href = "/apa"
  }

  openfeaturePage = (feature) => {
    switch (feature) {
      case 'panic_alert':
        window.location.href = "/feature/panic_alert"
        break;

      case 'mini_apps':
        window.location.href = "/feature/mini_apps"
        break;

      case 'committee':
        window.location.href = "/feature/committee"
        break;

      case 'intercom':
        window.location.href = "/feature/intercom"
        break;

      case 'feedback':
        window.location.href = "/feature/feedback"
        break;

      case 'facility_booking':
        window.location.href = "/feature/facility_booking"
        break;

      case 'family_sharing':
        window.location.href = "/feature/family_sharing"
        break;

      case 'e_form':
        window.location.href = "/feature/e_form"
        break;

      case 'e_doc':
        window.location.href = "/feature/e_doc"
        break;

      case 'visitor_management':
        window.location.href = "/feature/visitor_management"
        break;

      case 'highlights':
        window.location.href = "/feature/highlights"
        break;

      case 'weather':
        window.location.href = "/feature/weather"
        break;

      case 'one_to_many':
        window.location.href = "/feature/one_to_many"
        break;

      case 'e_billing':
        window.location.href = "/feature/e_billing"
        break;

      case 'discussion':
        window.location.href = "/feature/discussion"
        break;
    }
  }


  checkimgSrc = (file) => {

    const files = file
    const fileType = files[0].type;
    const fileSizeInMB = files[0].size / (1024 * 1024); // in MB
    const valueAfterDeduce = 5 - fileSizeInMB;

    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {

      this.imgSrc = event.target.result

    }
  }


}
