import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FooterComponent } from "src/layout/footer/footer.component";
import { FeatureNavbarComponent } from "./navbar-feature/feature_navbar.component";
import { NavbarComponent } from "./navbar-landing/navbar.component";


@NgModule({
    declarations:[
        FooterComponent,
        NavbarComponent,
        FeatureNavbarComponent
    ],
    imports:[
        CommonModule,
        RouterModule,
    ],
    exports:[
        FooterComponent,
        NavbarComponent,
        FeatureNavbarComponent
    ],
    providers:[]
})

export class LayoutModule {}

