import { Component } from '@angular/core';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  title = 'landing-page';
  loadingRouteConfig: boolean;
  constructor(private router: Router) {
    this.router.events.subscribe((event: RouterEvent) => {
        //lazyloading start

        // console.log('event instanceof RouteConfigLoadStart', event instanceof RouteConfigLoadStart)
        // console.log('event instanceof RouteConfigLoadEnd', event instanceof RouteConfigLoadEnd)

        if(event instanceof RouteConfigLoadStart && event.route.path === 'lazy') {
          this.loadingRouteConfig = true;
        } 
        //lazy loading end
        else if(event instanceof RouteConfigLoadEnd && event.route.path === 'lazy') {
          // console.log('event end', event)
          this.loadingRouteConfig = false;
          // this.router.navigate(['/main']);

        }
    })
} 

}


