<!-- Navbar Start -->
<app-navbar [onlyLogo]="true">
</app-navbar>
<!-- Navbar End -->

<div id="hero-area" class="hero-area-bg set-padding">
  <div class="container">
    <div class="">

      <h1>Refund Policy</h1>
      <p class="content">To the extent permitted by the applicable law, all payments are non-refundable and we do not provide refunds or credits for any
        partial paid service periods, unitilized credits or unwatched service, including any redemption through a gift code or promotional code.
      </p>

    </div>
  </div>
</div>
