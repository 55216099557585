<div class="bg-darkgreen">
    <div class="container">
        <div class="row">

            <div class="col-md-12 col-sm-12 col-xs-12 text-center container-1">

                <div class="d-flex flex-column first-div">
                    <p class="title">Angsaku</p>
                    <p class="title">Scan me with mobile phone</p>
                    <div class="w-100 mt-2 mb-2">

                        <ng-container *ngIf="qrLink != ''; else defaultQR">
                            <div class="qr-content">


                                <div class="qr-code">
                                    <qrcode [qrdata]="qrLink" [width]="150"  [errorCorrectionLevel]="'M'" [margin]="2"></qrcode>
                                
                                    <img style="margin-top: -195px" src="assets/img/angsaku-logo2.png" alt="assets/img/angsaku-logo2.png" width="40">
                                </div>

                                
                            </div>
                            
                        </ng-container>
                        

                        <ng-template #defaultQR>
                            <img [src]="qrImg" alt="" width="150" height="150">
                        </ng-template>
                        
                       

                        <!-- <img [src]="qrImg" alt="" width="150" height="150"> -->
                    </div>
                    <!-- <qrcode class="mt-3" [qrdata]="qrcode" [size]="256" [level]="'H'" [version]="5"></qrcode> -->
                    <div class="w-100 d-flex justify-content-center">
                    <div id="owl-trans-iphone" class="iphone-bg">
                        <div class="item">
                            <img class="img-responsive centered" src="assets/img/angsaku-download-mobile2.jpg" alt="">
                        </div>
                    </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</div>
<app-footer [removePTop]="true"></app-footer>
