import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutes, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/shared/shared.module';
import { ErrorPageComponent } from './module/error-page/error-page.component';
import { RouterModule } from '@angular/router';
import { LayoutModule } from 'src/layout/layout.module';
import { GlobalService } from './core/_services/global.service';
import { GeoLocationComponent } from './module/geo-location/geo-location.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function appInit(globalService: GlobalService) {
  return () => globalService.getEnv();
}


@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    GeoLocationComponent,
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    SharedModule,
    LayoutModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
  ],
  providers: [
    GlobalService,
    // { provide: APP_INITIALIZER, useFactory: appInit, multi: true, deps: [GlobalService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
