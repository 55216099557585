<!--
VIEW IN FULL SCREEN MODE
FULL SCREEN MODE: http://salehriaz.com/404Page/404.html

DRIBBBLE: https://dribbble.com/shots/4330167-404-Page-Lost-In-Space
-->

<!-- <body class="bg-purple">
        
    <div class="stars">
        <div class="custom-navbar">
            <div class="brand-logo">
                <img src="http://salehriaz.com/404Page/img/logo.svg" width="80px">
            </div>
            <div class="navbar-links">
                <ul>
                  <li><a href="http://salehriaz.com/404Page/404.html" target="_blank">Home</a></li>
                  <li><a href="http://salehriaz.com/404Page/404.html" target="_blank">About</a></li>
                  <li><a href="http://salehriaz.com/404Page/404.html" target="_blank">Features</a></li>
                  <li><a href="http://salehriaz.com/404Page/404.html" class="btn-request" target="_blank">Request A Demo</a></li>
                </ul>
            </div>
        </div>
        <div class="central-body">
            <img class="image-404" src="http://salehriaz.com/404Page/img/404.svg" width="300px">
            <a href="http://salehriaz.com/404Page/404.html" class="btn-go-home" target="_blank">GO BACK HOME</a>
        </div>
        <div class="objects">
            <img class="object_rocket" src="http://salehriaz.com/404Page/img/rocket.svg" width="40px">
            <div class="earth-moon">
                <img class="object_earth" src="http://salehriaz.com/404Page/img/earth.svg" width="100px">
                <img class="object_moon" src="http://salehriaz.com/404Page/img/moon.svg" width="80px">
            </div>
            <div class="box_astronaut">
                <img class="object_astronaut" src="http://salehriaz.com/404Page/img/astronaut.svg" width="140px">
            </div>
        </div>
        <div class="glowing_stars">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>

        </div>

    </div>

</body> -->

<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <div></div>
            <h1>404</h1>
        </div>
        <h2 class="mb-3">Page not found</h2>
        <p class="mb-3">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        <a href='/main' >home page</a>
    </div>
</div>
<div style="font-size: 10px; color: #696969;">{{version}}</div>

