import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/shared/shared.module';
import { LandingPageComponent } from './landing-page.component';
import { LandPageRoutes } from './landing-page.routing';
import { TosComponent } from './tos/tos.component';
import { PolicyComponent } from './policy/policy.component';
import { LayoutModule } from 'src/layout/layout.module';
import { DownloadComponent } from './download/download.component';
import { AppPageComponent } from './app-page/app-page.component';
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { DeeplinkComponent } from './deeplink/deeplink.component';
import { YcRefundPolicyComponent } from './yc-refund-policy/yc-refund-policy';



@NgModule({
    imports: [
        RouterModule.forChild(LandPageRoutes),
        SharedModule,
        LayoutModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    declarations: [
        LandingPageComponent,
        TosComponent,
        PolicyComponent,
        DownloadComponent,
        AppPageComponent,
        DeeplinkComponent,
        YcRefundPolicyComponent
    ],
    providers:[
    ],
    exports:[LandingPageComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class LandPageModule { }
